
import React, { Component, PropTypes } from 'react';
import ClassNames from 'classnames';


export default class AutoVideo extends Component {


  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.isPlaying) {
      this.refs.theVideo.play();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPlaying === false && this.props.isPlaying === true) {
      this.refs.theVideo.play();
    }
  }


  render() {
    return (
      <div className="auto-video">
        <video ref="theVideo" loop={this.props.loop}>
          <source src={this.props.src} type="video/mp4" />
        </video>
      </div>
    );
  }
}
